import {Component, Injector} from '@angular/core';
import {AppComponentBase} from '@shared/app-component-base';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>`
})
export class RootComponent extends AppComponentBase {

  constructor(
    injector: Injector,
  ) {
    super(injector);

  }

}
